.section-full {
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

.home-2-about {
    width: 100%;
    height: auto; /* Ensure it scales properly */
    background-size: cover;
    background-position: center;
}

@media (max-width: 992px) {
    .home-2-about {
        background-position: center;
    }
}

@media (max-width: 768px) {
    .about-home-2 {
        padding: 15px; /* Adjust padding for smaller screens */
    }

    .section-content {
        padding: 0; /* Remove unnecessary padding */
    }
}
